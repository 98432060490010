import React, {useState, useEffect} from 'react'
import axios from 'axios';


const NavComponent = ({store}) => { 

  return (
    <div className="Service_heading-container__xwIlA">
      <div className="container">
        <div className="Header_header__container__4eFtM">
          <nav className="navbar navbar-expand navbar-light">
            <span className="Header_navbar__brand__IOm2J navbar-brand" style={{display:"flex",alignItems:"center",gap:"10px"}}>
              <img
                alt="Demo Logo"
                loading="lazy"
                width="60"
                height="42"
                decoding="async"
                className="d-sm-none"
                style={{ color: 'transparent' }}
                src={store.banner}
              />
              <img
                alt="Logo"
                loading="lazy"
                width="120px"
                decoding="async"
                className="d-none d-sm-block"
                style={{ color: 'transparent' }}
                src={store.banner}
                
              />
              {/* <h4 style={{margin: "0"}}>{store.store_name}</h4> */}
            </span>
            <div className="ms-auto gap-2 gap-sm-3 navbar-nav">
              {/* <a
                href="#"
                rel="noopener noreferrer"
                target="_blank"
                className="Heading_nav-container__help-btn__AuQdQ Header_nav-container__help-btn__VwjnM"
              >
                <span className="Heading_btn-text__AJjEr">Home</span>
              </a> */}
              <button className="Heading_btn__Rajeo Heading_btn--secondary___cmCX">
                <span className="Heading_btn-text__AJjEr">Contact Us</span>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NavComponent;