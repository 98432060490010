import React from 'react';


import './404.css';

const Page404 = () => {
	

  // Function to go back to the previous page
  const goBack = () => {
    window.history.back();
  };

  return (
    <section className="page_404">
      <div className="container">
        <div className="row">  
          <div className="col-sm-12">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center">404</h1>
              </div>
              
              <div className="content_box_404">
                <h3 className="h2">
                  Looks like you're lost
                </h3>
                
                <p>The page you are looking for is not available!</p>
                
                <button onClick={goBack} className="link_404">Go to Previous Page</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Page404;
