import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';
import Widget from './Widget';
import Page404 from './Page404';




function App() {  
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState({});
  const [shoe404 , setShoe404] = useState(false);


  useEffect(() => {
    // Get the current pathname from the URL
    const pathname = window.location.pathname;

    // Extract the slug part
    const parts = pathname.split('/');
    const extractedSlug = parts[parts.length - 2]; // This will be 'fitness-world' for the given URL

    // Convert the extractedSlug to the expected URL format
    const formattedSlug = `/purchase/${extractedSlug}/`;

    axios.get('https://cimauae.avmdevs.com/wp-json/dokan/v1/stores')
      .then((response) => {
        // Find the store with the exact matching shop_url
        const store = response.data.find((store) => {
          const storeUrl = new URL(store.shop_url); // Parse the URL
          console.log(storeUrl.pathname);
          console.log(formattedSlug);
          return storeUrl.pathname === formattedSlug;
        });

        if (!store) {
          setLoading(false);
          setShoe404(true);
          return;
        }

        store.store_slug = extractedSlug;
        setStore(store);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching store data:', error);
      });
  }, []);


    


    return (
      <div id="__next">

        {loading && <div>Loading...</div>}

        {shoe404 && <Page404 />}

        {!loading && !shoe404 && ( 
          
          <div class="__variable_725860 __variable_bfdb32 __variable_51677f __className_725860 __variable_81aefc __variable_14b3b2 __variable_789070">
            <Navbar store={store}/>          
            <Widget store={store} />
            <Footer store={store}/>

       
            </div>      
          )} 

    </div>  
    );

  }

export default App;